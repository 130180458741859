<!--
 * @Description: 用户审核--用户实名审核-未通过列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-06-03 15:10:48
 -->
<template>
  <div class="realNameAuditListNoPass-wrapper">
    <list-item :status="2"></list-item>
  </div>
</template>

<script>
import ListItem from './RealNameAuditListItem.vue'

export default {
  name: '',
  components: {
    'list-item': ListItem
  },
  data () {
    return {
    }
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.realNameAuditListNoPass-wrapper {
    height: 100%;
}
</style>
